
import request from '@/utils/request'

export function getCheckSchedule(data) {
  return request({
    url: 'checkSchedule/getCheckSchedule',
    method: 'post',
    data,
  })
}


export function setExculteTimes(data) {
  return request({
    url: 'checkSchedule/setExculteTimes',
    method: 'post',
    data,
  })
}



